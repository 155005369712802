thoughts = {
    "0": "Oh Sadhguru,\nDo not go ahead of me because I may not follow you, Do not come behind me because I may not lead you. \nPlease walk side by side and guide me",
    "1": "श्रीराम जयराम जयजयराम\nम्हणजे\nकृतज्ञता, प्रफुल्लित रहाणे, जो जे वांच्छिल तो ते लाहो.",
    "2": "रामनाम घेणे,\n हे शब्द उच्चारणे,\nजरी वाटले तरी आनंदात राहणे,\nत्याचे प्रकटीकरण आहे.",
    "3": "Moment to Moment\nजीवन जगावे,\nसंचिताचे कौतुक\nपहावे",
    "4": "जेथे इतिहास समाप्त होतो,\nतेथे\nअध्यात्माची सुरुवात होते.",
    "5": "स्वत:ला\nयोग्य वळण लावण्याकरिता\nरामनाम घ्या.\nबंधनातून मुक्त करते ती\nजपमाळ.",
    "6": "आपण श्री महाराजांचे आहो.\nफालतू चौकशी, तक्रारी, दोष बघणे, निंदा करणे\nटाळा.",
    "7": "परमार्थ म्हणजे\nभूक आनंदाची,\nतळमळ सौजन्याची !",
    "8": "उपासना म्हणजे विश्वबंधुत्व,\nभगवंताच्या सर्वव्यापी सत्तेचे भान\n आपल्यातला राम ओळखणे,\n फुलवणे, मोठा करणे.",
    "9": "LOVE म्हणजे\nअपेक्षा-शून्यता\nनि:स्वार्थपणा,\nआसक्तीचा अभाव,\nफक्त आपुलकी",
    "10": "उपासकाने\nआपला आनंद विकसित करावा,\nआपले व्यक्तिमत्व फुलवावे,\nप्रेमाचा फुलोरा द्यावा,\nआणि\nमनाची दौलत मुक्तपणे वाटावी.",
    "11": "अध्यात्मात\nकर्तव्याला आणि कृतज्ञतेला\n पर्याय नाही.",
    "12": "भगवंत आपल्याला आपल्या\nक्षमतेप्रमाणे देतो.\nआपण जास्त मागतो म्हणून\nमिळालेले कमी वाटते.",
    "13": "THINK before you talk\nand\nDOUBLE THINK\nbefore you act.",
    "14": "प्रार्थनेमुळे देव बदलत नाही\nतर भक्तिभावाने करणारा\nमनुष्य बदलतो",
    "15": "एखादी चांगली Idea जेव्हा\nConceive होते तेव्हा(लगेच)\nत्यांच्या पुण्य-मोजणीला\nसुरूवात होते,\nम्हणून नेहमी चांगले विचार करा",
    "16": "Time you have spent\nin smiling,\nis the time\nyou have spent with God.",
    "17": "Basic Producer is\nGOD\nwe are all reproducers.",
    "18": "Everybody is\nan Advocate of self\nand a Judge for others.",
    "19": "राम नाम हे शाश्वत,\nचिरंतन समाधान देणारे\nनैसर्गिक शक्तिवर्धक आहे.",
    "20": "प्रत्येक प्रसंगी\nThank You Maharaj\nम्हणायची सवय लावा.",
    "21": "कर्तव्य केल्यावर\nपरतफेडीची अपेक्षा नको.",
    "22": "सुख जितके तात्पुरते आहे,\nतितकेच दुःख पण तात्पुरते आहे",
    "23": "Switch On and Switch Off\nis the key of happiness.",
    "24": "I should not\nwaste my time\nin auditing.",
    "25": "I am right\nbut\nhe is also not wrong\n असे म्हणून कमीत कमी त्रास\n आणि जास्तीत जास्त आनंद घ्यावा.",
    "26": "भगवंताचे नाम म्हणजे\nAnaesthesia\nसुखाची किंवा दुःखाची संवेदना\nकमी करणारा.",
    "27": "राम नाम म्हणजे भूतकाळ विसरणे,\nवर्तमानात राहणे,\nआणि\nभविष्यकाळाला सामोरे जाणे होय.",
    "28": "सत्पुरुषांच्या पवित्र स्मृति\nह्या स्फुल्लिंग असतात.\nत्या आपल्या हृदयात जतन करुन\nसदैव प्रज्वलित ठेवाव्या.",
    "29": "Copy the consciousness of the Master\nसत्पुरुषांचा Internal Joy तुम्ही घ्या.",
    "30": "बुद्धिदाता, कर्ता, वक्ता, श्रोता सर्व माझा हृदयस्थ भगवंत.\nस्वतःकडे लक्ष द्यावे.\nआपल्या आनंदसागरात समाविष्ट होवून जावे.",
    "31": "आपला संकल्प फार छोटा असावा मुख्यत्वे एकच असावा.\nतो म्हणजे मला आनंदात रहायचे आहे."
}



module.exports.getThought = () => thoughts[Math.ceil(Math.random() * 31) % 32]